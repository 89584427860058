import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import PortfolioSection from "../components/portfolio-section";
import ReviewSection from "../components/review-section";
import { InlineWidget } from "react-calendly";

const WWDPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "What We Do" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			img1: wpMediaItem(title: { eq: "What-We-Do-Image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`WHAT \n<span class="text-secondary">WE DO</span>`}
						description="RJM Digital offers a range of services to help you get better website results and generate more revenue in order to take your business to the next level."
					/>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Need some website help?
								</h2>
								<p>
									RJM provides <strong>website solutions</strong> for small to medium size businesses.
									Whether you need us to design or redesign your website, transform
									your SEO or create Google Ads, help with branding or e-commerce,
									our experienced team would love to work with you.
								</p>
								<p>
									At RJM Digital we understand that every business is different and
									want their website to reflect this. This is why our <strong>experienced</strong> team
									are dedicated to providing a <strong>bespoke</strong> approach to every project,
									tailoring every step of the process to meet your needs and requirements. 
								</p>
								<p>
									<strong>Get in contact</strong> today by phone, email or by booking a meeting
									directly to discuss your next project!
								</p>
							</Col>
							<Col lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(54deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className=" py-5 py-lg-7"
				>
					<Container>
						<Row className="align-items-center px-lg-4 g-5">
							<Col lg={4} className=" ">
								<h2 className="fs-1  mb-4">
									<Link to="/web-design" className="ssp-bold white-link">
										WEB DESIGN
									</Link>
								</h2>
								<p className="text-white">
									Creating stylish, functional, and responsive websites that your customers will
									love.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1 text-white mb-4">
									<Link to="/web-development" className="ssp-bold white-link">
										WEB DEVELOPMENT
									</Link>
								</h2>
								<p className="text-white">
									Creating bespoke websites, incorporating all the
									elements your business needs and more.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1 text-white mb-4">
									<Link
										to="/sustainable-web-design"
										className="ssp-bold white-link"
									>
										SUSTAINABLE WEB
									</Link>
								</h2>
								<p className="text-white">
									Designing sustainable and accessible websites,
									hosted on green servers.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1 text-white mb-4">
									<Link to="/google-ads" className="ssp-bold white-link">
										GOOGLE ADS
									</Link>
								</h2>
								<p className="text-white">
									Creating Google Ads that get you in front of your target
									audience and convert them into customers.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1 text-white mb-4">
									<Link
										to="/e-commerce-websites"
										className="ssp-bold white-link"
									>
										E-COMMERCE
									</Link>
								</h2>
								<p className="text-white">
									Developing online shopping that enables your business and
									revenue to grow.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1  mb-4">
									<Link to="/seo" className="ssp-bold white-link">
										SEO
									</Link>
								</h2>
								<p className="text-white">
									Developing an SEO strategy that lets your business be
									found on search engines by new customers.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1  mb-4">
									<Link to="/web-design" className="ssp-bold white-link">
										TRAINING
									</Link>
								</h2>
								<p className="text-white">
									Providing you with in-house SEO and Google Ads training
									so you can succeed.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1 mb-4">
									<Link to="/contact-us" className="ssp-bold white-link">
										CONSULTANCY
									</Link>
								</h2>
								<p className="text-white">
									Bespoke in-house support from our expert and
									professional team.
								</p>
							</Col>
							<Col lg={4} className=" ">
								<h2 className="fs-1  mb-4">
									<Link to="/branding" className="ssp-bold white-link">
										BRANDING
									</Link>
								</h2>
								<p className="text-white">
									Branding advice and support to ensure your business
									is easily recognisable. 
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="py-5 py-lg-7 bg-light-grey">
					<PortfolioSection page="What We Do" />
				</section>
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-md-7 py-lg-7 bg-light-grey">
					<ReviewSection page="What We Do" />
				</section>
			</Layout>
		</>
	);
};

export default WWDPage;
